import React from "react";
import "./VideoSection.css"

const videoSection = () => {
    return (
        <div className="videoContainer">
            <iframe loading='lazy' className="videoMain" src="https://www.youtube.com/embed/32kQ1e-bQjc"
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen>

            </iframe>
        </div>
    );
}

export default videoSection
