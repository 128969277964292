import React from "react";
import './WhitePaperBox.css'
import { saveAs } from 'file-saver';

// @ts-ignore
const WhitePaperBox = ({ heading, downloadLink }) => {
    const downloadPdf = (heading: string) => {
        console.log('hello')
        if (heading === 'English') {
            saveAs(
                "https://rocknverse.com/litepaperEN.pdf",
                "rocknverse_litepaperEN.pdf"
            )
        } else {
            saveAs(
                "https://rocknverse.com/litepaperTR.pdf",
                "rocknverse_litepaperTR.pdf"
            )
        }
    }


    return (
        <>
            <div className="whitepaperBoxContainer">
                <div className="whitepaperBoxHeading">
                    {heading}
                </div>
                <div className="whitepaperIconContainer">
                    <i className="fa-regular fa-file-pdf fa-2xl"></i>

                </div>

                <div className="whitepaperBoxDownload" onClick={() => downloadPdf(heading)}>
                    {heading === 'English' ? 'Download' : 'İndir'}
                </div>
            </div>
        </>
    );
}

export default WhitePaperBox;
