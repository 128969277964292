import React, {Component} from "react";
import {Link} from "react-router-dom"
import "./notFoundPage.css"
import ReactGA from "react-ga";

class notFoundPage extends Component<any, any> {
    initReactGA = () => {
        ReactGA.initialize("G-D9SYYV5S60");
        ReactGA.pageview('test-init-pageview');
    };

    componentDidMount() {
        this.initReactGA();
    }

    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div className="pageContainer">
                <div className="container-fluid">
                    <div className="div1">
                        <a className="notFoundtitle">404</a>
                    </div>
                    <div className="div2">
                        <a className="notFoundDesc">Oops. There is no place like this...</a>
                    </div>
                    <div className="div3">
                        <Link className="hPButton" type="button" to="/">Back to Homepage</Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default notFoundPage
