import React from "react";
import './Title.css';

class Title extends React.Component<{ title: any }> {
    render() {
        let {title} = this.props;
        return (
            <div className='titleContainer'>
                <p>{title}</p>
            </div>
        );
    }
}

export default Title;
