import React from "react";
import './RockCommunity.css';
import Title from "../../components/title/Title";
import titleBackground from '../../assets/titleBackground.png';
import img5 from "../../assets/Group 200.png"
import img6 from "../../assets/Group 201.png"
import img7 from "../../assets/Group 202.png"
import img8 from "../../assets/Group 203.png"

import RockCommunityCard from "../../components/rockCommunityCard/RockCommunityCard";
import { useTranslation } from "react-i18next";


const RockCommunity = () => {

    const { t } = useTranslation()

    const RockCommunityItems = [{
        heading: t('rockCommunity.RockCommunityItems.cardOneHeading'),
        img: img5,
        paragraph: t('rockCommunity.RockCommunityItems.cardOneParagraph')
    },
        {
            heading: t('rockCommunity.RockCommunityItems.cardTwoHeading'),
            img: img6,
            paragraph: t('rockCommunity.RockCommunityItems.cardTwoParagraph')
        },
        {
            heading: t('rockCommunity.RockCommunityItems.cardThreeHeading'),
            img: img7,
            paragraph: t('rockCommunity.RockCommunityItems.cardThreeParagraph')
        },
        {
            heading:t('rockCommunity.RockCommunityItems.cardFourHeading'),
            img: img8,
            paragraph: t('rockCommunity.RockCommunityItems.cardFourParagraph')
        }
    ];

    return (
        <div className='rockCommunityContainer'>
            <div className="rockCommunityTitle">
                <div className="rockCommunityTitleText">
                    <p className='rockCommunityTitleFirst'>{t('rockCommunity.titleParagraphOne')}</p>
                    <Title title={t('rockCommunity.titleComponentOne')}/>
                    <Title title={t('rockCommunity.titleComponentTwo')}/>
                    <p className='rockCommunityTitleSecond'>{t('rockCommunity.titleParagraphTwo')}</p>
                </div>
                <div className="rockCommunityTitleBackground">
                    <img loading='lazy' src={titleBackground} alt=""/>
                </div>
            </div>
            <div className="container-fluid">
                <div className="rockCommunityCards">

                    <div className="row">
                        {
                            RockCommunityItems.map((curr) => {
                                return (
                                    <div className='col-12 col-lg-3'>
                                        <RockCommunityCard cardImage={curr.img} cardHeading={curr.heading}
                                                           cardParagraph={curr.paragraph}/>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );

}
export default RockCommunity;
