import React from "react";
import './UpComingProjectCard.css';
import lockedLeft from '../../assets/launchpad_lock.png';
import borderImage from "../../assets/borderEffect.png";
import { useTranslation } from "react-i18next";

const UpComingProjectCards = () => {

    const { t } = useTranslation();

    return (
        <div className='container-fluid'>
            <div className='upComingProjectCardContainer'>
                <div className="projectCardRightBottom">
                    <p>{t('projects.projectCard.soon')}</p>
                </div>
                <div className="innerContainer">
                    <img className='upComingBorderImageFirst' src={borderImage} alt=""/>
                    <img className='upComingBorderImageSecond' src={borderImage} alt=""/>
                    <div className="upComingProjectCardLeft">
                        <img src={lockedLeft} loading='lazy' alt=""/>
                    </div>
                    <div className="upComingProjectCardRight">

                        <div className="upComingProjectCardRightCenter">
                            <div className="upComingProjectCardAttributeContainer">
                                <div className="row">
                                    <p className="comingSoon">
                                        {t('projects.upcomingProjects.card.text')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpComingProjectCards;
