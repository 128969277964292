import React from "react";
import "./NewRoadmap.css";
import Title from "../../components/title/Title";
import { useTranslation } from "react-i18next";
import roadmapGuitar from "../../assets/WEB_GUITAR_FINAL2.png";
import GuitarLine, {
  GuitarLineDirection,
  GuitarLineType,
} from "../../components/guitarLine/GuitarLine";
import useMedia from "../../hooks/useMedia";

const NewRoadmap = () => {
  const { t } = useTranslation();
  const isMobile = useMedia("(max-width: 480px)");
  const isTablet = useMedia("(max-width: 1024px) and (min-width: 480px)");
  return (
    <div className="newRoadmapContainer">
      <div className="titles">
        <p className="roadmapTitle" id="Roadmap">
          ROCK'N'VERSE
        </p>
        <p>
          <Title title={t("roadmap.title")} />
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <div className="roadmapImage">
          <img src={roadmapGuitar} alt="" />
        </div>
        <div className="roadmapItems">
          <GuitarLine
            className="guitarLineSection1"
            type={GuitarLineType.FIRST}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapItemOne.heading")}
            description={t("roadmap.roadmapItemOne.description")}
            width={isTablet ? 100 : isMobile ? 30 : 300}
          />
          <GuitarLine
            className="guitarLineSection2"
            type={GuitarLineType.FIRST}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapItemTwo.heading")}
            description={t("roadmap.roadmapItemTwo.description")}
            width={isTablet ? 100 : isMobile ? 35 : 250}
          />
          <GuitarLine
            className="guitarLineSection3"
            type={GuitarLineType.FIRST}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapItemThree.heading")}
            description={t("roadmap.roadmapItemThree.descriptionFirst")}
            subDescription1={t("roadmap.roadmapItemThree.subDescription")}
            width={isTablet ? 40 : isMobile ? 10 : 210}
          />
          <GuitarLine
            className="guitarLineSection4"
            type={GuitarLineType.FIRST}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapItemFour.heading")}
            description={t("roadmap.roadmapItemFour.description")}
            width={isTablet ? 30 : isMobile ? 35 : 150}
          />
          <GuitarLine
            className="guitarLineSection5"
            type={GuitarLineType.FIRST}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapItemFive.heading")}
            description={t("roadmap.roadmapItemFive.description")}
            width={isTablet ? 100 : isMobile ? 35 : 260}
          />
          <GuitarLine
            className="guitarLineSection6"
            type={GuitarLineType.FIRST}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapItemSix.heading")}
            description={t("roadmap.roadmapItemSix.description")}
            width={isTablet ? 100 : isMobile ? 50 : 220}
          />
          <GuitarLine
            className="guitarLineSection7"
            type={GuitarLineType.FIRST}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapItemSeven.heading")}
            description={t("roadmap.roadmapItemSeven.description")}
            width={isTablet ? 35 : isMobile ? 50 : 190}
          />
          <GuitarLine
            className="guitarLineSection8"
            type={GuitarLineType.FIRST}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapItemEight.heading")}
            description={t("roadmap.roadmapItemEight.description")}
            width={isTablet ? 25 : isMobile ? 30 : 180}
          />
          <GuitarLine
            className="guitarLineSection9"
            type={GuitarLineType.FIRST}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapItemNine.heading")}
            description={t("roadmap.roadmapItemNine.description")}
            width={isTablet ? 100 : isMobile ? 30 : 300}
          />
          <GuitarLine
            className="guitarLineSection10"
            type={GuitarLineType.FIRST}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapItemTen.heading")}
            description={t("roadmap.roadmapItemTen.description")}
            width={isTablet ? 100 : isMobile ? 35 : 250}
          />
          <GuitarLine
            className="guitarLineSection10"
            type={GuitarLineType.FIRST}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapItemEleven.heading")}
            description={t("roadmap.roadmapItemEleven.description")}
            width={isTablet ? 40 : isMobile ? 10 : 210}
          />
          <GuitarLine
            className="guitarLineSection10"
            type={GuitarLineType.SECOND}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapItemTwelve.heading")}
            description={t("roadmap.roadmapItemTwelve.description")}
            width={isTablet ? 30 : isMobile ? 35 : 150}
          />
          <GuitarLine
            className="guitarLineSection13"
            type={GuitarLineType.SECOND}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapItemThirteen.heading")}
            description={t("roadmap.roadmapItemThirteen.description")}
            width={isTablet ? 100 : isMobile ? 35 : 260}
          />
          <GuitarLine
            className="guitarLineSection14"
            type={GuitarLineType.SECOND}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapItemFourteen.heading")}
            description={t("roadmap.roadmapItemFourteen.description")}
            width={isTablet ? 100 : isMobile ? 50 : 220}
          />
          <GuitarLine
            className="guitarLineSection15"
            type={GuitarLineType.SECOND}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapItemFifteen.heading")}
            description={t("roadmap.roadmapItemFifteen.description")}
            width={isTablet ? 35 : isMobile ? 50 : 190}
          />
          <GuitarLine
            className="guitarLineSection16"
            type={GuitarLineType.THIRD}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapItemSixteen.heading")}
            description={t("roadmap.roadmapItemSixteen.description")}
            width={isTablet ? 25 : isMobile ? 30 : 180}
          />
          <GuitarLine
            className="guitarLineSection17"
            type={GuitarLineType.THIRD}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapItemSeventeen.heading")}
            description={t("roadmap.roadmapItemSeventeen.description")}
            width={isTablet ? 30 : isMobile ? 35 : 150}
          />
          <GuitarLine
            className="guitarLineSection18"
            type={GuitarLineType.THIRD}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapItemEighteen.heading")}
            description={t("roadmap.roadmapItemEighteen.description")}
            width={isTablet ? 100 : isMobile ? 35 : 260}
          />
          <GuitarLine
            className="guitarLineSection19"
            type={GuitarLineType.THIRD}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapItemNineteen.heading")}
            description={t("roadmap.roadmapItemNineteen.description")}
            width={isTablet ? 100 : isMobile ? 50 : 220}
          />
          <GuitarLine
            className="guitarLineSection20"
            type={GuitarLineType.THIRD}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapItemTwenty.heading")}
            description={t("roadmap.roadmapItemTwenty.description")}
            width={isTablet ? 35 : isMobile ? 50 : 190}
          />
          <GuitarLine
            className="guitarLineSection21"
            type={GuitarLineType.THIRD}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapItemTwentyone.heading")}
            description={t("roadmap.roadmapItemTwentyone.description")}
            width={isTablet ? 25 : isMobile ? 30 : 180}
          />
          <GuitarLine
            className="guitarLineSection23"
            type={GuitarLineType.FOURTH}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapItemTwentythree.heading")}
            description={t("roadmap.roadmapItemTwentythree.description")}
            width={isTablet ? 100 : isMobile ? 35 : 250}
          />
          <GuitarLine
            className="guitarLineSection24"
            type={GuitarLineType.FOURTH}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapItemTwentyfour.heading")}
            description={t("roadmap.roadmapItemTwentyfour.description")}
            width={isTablet ? 40 : isMobile ? 10 : 210}
          />
          <GuitarLine
            className="guitarLineSection25"
            type={GuitarLineType.FOURTH}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapItemTwentyfive.heading")}
            description={t("roadmap.roadmapItemTwentyfive.description")}
            subDescription1={t("roadmap.roadmapItemTwentyfive.subDescription1")}
            subDescription2={t("roadmap.roadmapItemTwentyfive.subDescription2")}
            subDescription3={t("roadmap.roadmapItemTwentyfive.subDescription3")}
            subDescription4={t("roadmap.roadmapItemTwentyfive.subDescription4")}
            width={isTablet ? 30 : isMobile ? 35 : 150}
          />
          <GuitarLine
            className="guitarLineSection22"
            type={GuitarLineType.THIRD}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapItemTwentytwo.heading")}
            description={t("roadmap.roadmapItemTwentytwo.description")}
            width={isTablet ? 100 : isMobile ? 30 : 300}
          />
          <GuitarLine
            className="guitarLineSection22"
            type={GuitarLineType.THIRD}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapMusicSection.heading")}
            description={t("roadmap.roadmapMusicSection.description")}
            width={isTablet ? 100 : isMobile ? 30 : 300}
          />
          <GuitarLine
            className="guitarLineSection26"
            type={GuitarLineType.FOURTH}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapItemTwentysix.heading")}
            description={t("roadmap.roadmapItemTwentysix.description")}
            width={isTablet ? 100 : isMobile ? 35 : 260}
          />
          <GuitarLine
            className="guitarLineSection27"
            type={GuitarLineType.FOURTH}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapItemTwentyseven.heading")}
            description={t("roadmap.roadmapItemTwentyseven.description")}
            width={isTablet ? 100 : isMobile ? 50 : 220}
          />
          <GuitarLine
            className="guitarLineSection28"
            type={GuitarLineType.FOURTH}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapItemTwentyeight.heading")}
            description={t("roadmap.roadmapItemTwentyeight.description")}
            width={isTablet ? 35 : isMobile ? 50 : 190}
          />
          <GuitarLine
            className="guitarLineSection29"
            type={GuitarLineType.FOURTH}
            direction={GuitarLineDirection.LEFT}
            title={t("roadmap.roadmapItemTwentynine.heading")}
            description={t("roadmap.roadmapItemTwentynine.description")}
            width={isTablet ? 25 : isMobile ? 30 : 180}
          />
        </div>
      </div>
    </div>
  );
};
export default NewRoadmap;
