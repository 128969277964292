import React, {useEffect} from "react";
import './ConnectWallet.css';
import {Modal} from 'react-bootstrap';
import Web3 from "web3";
import {setIsOpenModal, setWallet} from "../../store/walletSlice/walletSlice";
import {useAppDispatch, useAppSelector} from "../../hooks";

const ConnectWallet = () => {

    const wallet = useAppSelector(state => state.wallet);
    const dispatch = useAppDispatch()
    const handleClose = () => {
        dispatch(setIsOpenModal())
    }
    const connectMetamask = async () => {
        // @ts-ignore
        if (window.ethereum) {
            // @ts-ignore
            await window.ethereum.request({method: 'eth_requestAccounts'});
            // @ts-ignore
            window.web3 = new Web3(window.ethereum);
            //@ts-ignore
            dispatch(setWallet(window.web3.currentProvider.selectedAddress))
            // @ts-ignore
            console.log(window.web3.currentProvider.selectedAddress);


            return true;
        }
        return false;
    }
    const setModal = () => {
        if (wallet.currentWallet === '') {
            return (
                <div className="connectWalletContainer">
                    <button className="connectWalletButton" onClick={connectMetamask}>MetaMask</button>
                    <button className="connectWalletButton">WalletConnect</button>
                    <button className="connectWalletButton">WalletLink</button>
                    <button className="connectWalletButton">Ledger</button>
                </div>
            );
        } else {
            return (
                <div className='connectWalletContainer'>
                   <p className='connectedWallet'>
                       Connected With : {wallet.currentWallet}
                   </p>
                </div>
            );
        }
    }
    useEffect(() => {

    });
    return (
        <div>
            <Modal show={wallet.isOpenModal} onHide={handleClose} centered>
                <div className="connectWalletMainContainer">
                    <div className='closeButton' onClick={handleClose}>
                        X
                    </div>
                    <div className="connectWalletHeading">
                        <h2>  {wallet.currentWallet === '' ? 'Connect Wallet' : 'Wallet Connected'}</h2>
                    </div>
                    {setModal()}
                </div>
            </Modal>
        </div>
    );
}
export default ConnectWallet;
