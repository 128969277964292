import React from "react";
import './TeamMember.css';


class TeamMember extends React.Component<{ teamMemberImg: any, teamMemberName: any, teamMemberTitle: any }> {
    render() {
        let {teamMemberImg, teamMemberName, teamMemberTitle} = this.props;
        return (
            <div className='teamMemberContainer'>
                <img src={teamMemberImg} alt="" loading='lazy'/>
                <div className="teamMemberText1">
                    <p>{teamMemberName}</p>
                </div>
                <div className="teamMemberText2">
                    <p>{teamMemberTitle}</p>
                </div>
            </div>
        );
    }
}

export default TeamMember;
