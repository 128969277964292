import React, {useEffect, useState} from "react";
import './Whitepaper.css';
import borderImage from "../../assets/borderEffect.png";
import Footer from "../footer/Footer";
import WhitepaperInnerItem from "../../components/whitePaperInnerItem/WhitepaperInnerItem";
import {HashLink} from "react-router-hash-link";
import {useTranslation} from "react-i18next";


const Whitepaper = () => {

    const {t} = useTranslation();

    const [currentSection, setCurrentSection] = useState('Insights');


    const whitepaper = [
        {
            section: 'About Project',
            headingFirst: t('readmore.about.headingTitle'),
            headingDescription: t('readmore.about.headingDescription'),
            headingSecond: t('readmore.about.headingSecond'),
            description: [
                {
                    descriptionText: t('readmore.about.paragraphOne'),
                    descriptionImg: 'https://cdn.discordapp.com/attachments/942774198315671562/950146554549928016/111.jpg',
                },
                {
                    descriptionText: t('readmore.about.paragraphTwo'),
                    descriptionImg: 'https://cdn.discordapp.com/attachments/942774198315671562/950149291790463046/112.jpg',
                    isFootage: true
                },
                {
                    descriptionText: t('readmore.about.paragraphThree'),
                    descriptionImg: 'https://cdn.discordapp.com/attachments/942774198315671562/950150530943045642/113.png',
                    isFootage: false
                },
                {
                    descriptionText: t('readmore.about.paragraphFour')
                }

            ]
        },
        {
            section: 'Insights',
            headingFirst: t('readmore.litepaper.heading'),
            headingDescription: t('readmore.litepaper.description'),
            headingSecond: '123123213',
            description: ['usantium quas saepe tempore.']
        }
    ];
    useEffect(() => {
    });
    return (
        <>
            <div className="container">
                <div className='row whitePaperContainer'>
                    <HashLink to='/'><p className='whitePaperCloseButton'>X</p></HashLink>
                    <div className="col-12 col-lg-3">
                        <div className="whitePaperMenu">
                            <img src={borderImage} loading='lazy' alt=""/>
                            <div onClick={() => setCurrentSection('About Project')}
                                 className={currentSection === 'About Project' ? 'menuItemActive' : 'menuItem'}>
                                <p>{t('readmore.menu.about')}</p>
                            </div>
                            <div
                                onClick={() => setCurrentSection('Insights')}
                                className={currentSection === 'Insights' ? 'menuItemActive' : 'menuItem'}>
                                <p>{t('readmore.menu.litepaper')}</p>
                            </div>
                            <div className="menuItem">
                                <p>{t('readmore.menu.whitepaper')}</p>
                            </div>
                            <div className="menuItem">
                                <p>{t('readmore.menu.branding')}</p>
                            </div>

                        </div>
                    </div>
                    <div className="col-12 col-lg-9">
                        {
                            whitepaper.filter((curr) => {
                                return curr.section === currentSection
                            }).map((curr) => {
                                console.log(curr);
                                return (
                                    <WhitepaperInnerItem headingFirst={curr.headingFirst}
                                                         headingSecond={curr.headingSecond}
                                                         headingDescription={curr.headingDescription}
                                                         description={curr.description}/>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
            <Footer/></>
    );
}

export default Whitepaper;
