import './Advisors.css'
import Title from '../../components/title/Title'
import TeamMember from '../../components/teamMember/TeamMember'
import instagram from '../../assets/instagram-line.png'
import demirdemirkan from '../../assets/Demir Demirkan_ADVISORY BOARD.jpg'
import { useTranslation } from 'react-i18next'
import erencaner from '../../assets/titleBackground.png'
import cansengun from '../../assets/Can Şengün.jpg'

const TeamMembers = () => {
  const { t } = useTranslation()
  return (
    <div className='advisorsContainer'>
      <div className='advisorsHeading'>
        <p>
          <Title title={t('advisoryBoard.title')} />
        </p>
      </div>
      <div className='advisorsCards'>
        <div className='container-fluid'>
          <div className='row justify-content-center'>
            <div className='col-6 col-md-6 col-lg-4 col-xl-2'>
              <TeamMember
                teamMemberImg={cansengun}
                teamMemberName={'Can Şengün'}
                teamMemberTitle={''}
              />
              <div className='SocialMediaIcons'>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://www.instagram.com/cansengun1/'
                >
                  <img src={instagram} loading='lazy' alt='' />
                </a>
              </div>
            </div>
            <div className='col-6 col-md-6 col-lg-4 col-xl-2'>
              <TeamMember
                teamMemberImg={demirdemirkan}
                teamMemberName={'Demir Demirkan'}
                teamMemberTitle={''}
              />
              <div className='SocialMediaIcons'>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://www.instagram.com/demirdemirkan'
                >
                  <img src={instagram} loading='lazy' alt='' />
                </a>
              </div>
            </div>
            <div className='col-6 col-md-6 col-lg-4 col-xl-2'>
              <TeamMember
                teamMemberImg={erencaner}
                teamMemberName={'Eren Caner'}
                teamMemberTitle={''}
              />
              <div className='SocialMediaIcons'>
                <a target='_blank' rel='noreferrer' href=''>
                  <img src={instagram} loading='lazy' alt='' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeamMembers
