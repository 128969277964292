import React, { Component } from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'
import Header from '../header/Header'
import Description from '../description/Description'
import RockCommunity from '../rockCommunity/RockCommunity'
import VideoSection from '../videoSection/VideoSection'
import Projects from '../projects/Projects'
import UpComingProjectCards from '../upComingProjectCards/UpComingProjectCards'
import Roadmap from '../roadmap/Roadmap'
import NewRoadmap from '../newRoadmap/NewRoadmap'
import FurtherProjects from '../furtherProjects/FurtherProjects'
import TeamMembers from '../teamMembers/TeamMembers'
import Advisors from '../advisors/Advisors'
import RecordLabel from '../recordLabel/RecordLabel'
import Footer from '../footer/Footer'
import Rockstars from '../rockStars/Rockstars'
import ReactGA from 'react-ga'

class Home extends Component<any, any> {
  initReactGA = () => {
    ReactGA.initialize('G-D9SYYV5S60')
    ReactGA.pageview('test-init-pageview')
  }
  componentDidMount () {
    this.initReactGA()
  }

  render () {
    return (
      <>
        <div className='container'>
          <ParallaxProvider>
            <Header />
          </ParallaxProvider>
          <ParallaxProvider>
            <Description />
          </ParallaxProvider>
          <RockCommunity />
          <VideoSection />
          <Projects />
          <UpComingProjectCards />
          <NewRoadmap />
          <FurtherProjects />
          <TeamMembers />
          <Advisors />
          {/* <Rockstars/> */}
          <RecordLabel />
        </div>
        <div>
          <Footer />
        </div>
      </>
    )
  }
}

export default Home
