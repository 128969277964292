import React, { useState } from 'react'
import mosh from '../../assets/mosh.png'
import discord from '../../assets/joindiscord.png'
import headerImage from '../../assets/newHeaderImg.png'
import './Header.css'
import { Animated } from 'react-animated-css'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Header = () => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className='headerContainer'>
      <div className='headerLeft'>
        <Animated
          animationIn={'slideInLeft'}
          animationOut='fadeOut'
          isVisible={true}
        >
          <div className='headerLeftHeading mb-5'>
            <p>{t('header.forThe')}</p>
            <p className='headerStroke'>{t('header.firstTime')}</p>
            <p>{t('header.inTheWorld')}...</p>
          </div>
        </Animated>
        <Animated
          animationIn={'slideInRight'}
          animationOut='fadeOut'
          isVisible={true}
        >
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <div className='headerLeftDescription mb-5'>
              <p>{t('header.description1')}</p>
              <p>{t('header.description2')}</p>
            </div>
          </div>
          <div className='headerLinkContainer'>
            <Link to='/readMore'>
              <div className='headerLeftNote'>
                <img className='' loading='lazy' src={mosh} alt='' />
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => setIsOpen(false)}
                >
                  <p>{t('header.readMore')}</p>
                </div>
              </div>
            </Link>
            <a
              target='_blank'
              rel='noopener'
              href='https://discord.com/invite/rocknverseofficial'
            >
              <div className='headerLeftNote'>
                <img className='' loading='lazy' src={discord} alt='' />
                <div style={{ cursor: 'pointer' }}>
                  <p style={{ color: 'white' }}>{t('header.joinDiscord')}</p>
                </div>
              </div>
            </a>
          </div>
        </Animated>
      </div>
      <div className='headerRight '>
        <Animated
          animationIn='slideInDown'
          animationOut='fadeOut'
          isVisible={true}
        >
          <img
            loading='lazy'
            className='headerImg animate__zoomIn'
            src={headerImage}
            alt=''
          />
        </Animated>
        <img
          loading='lazy'
          className='headerImgMobile'
          src={headerImage}
          alt=''
        />
      </div>

      <Modal show={isOpen} centered>
        <Modal.Body>
          <p className='modalParagraph'>
            Woohoo, you're reading this text in a modal! Woohoo, you're reading
            this text in a modal! Woohoo, you're reading this text in a modal!
            Woohoo, you're reading this text in a modal! Woohoo, you're reading
            this text in a modal! Woohoo, you're reading this text in a modal!
          </p>
          <div className='modalCloseButton' onClick={() => setIsOpen(false)}>
            <p>Close</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default Header
