import React, { useEffect, useState } from 'react';
import './mint.css';
import nft1 from '../../assets/nft1.jpg';
import nft2 from '../../assets/nft2.jpg';
import nft3 from '../../assets/nft3.jpg';
import Footer from '../footer/Footer';


const Mint = () => {

    const nftArray = [nft1, nft2, nft3];
    const [currentImage, setCurrentImage] = useState(0);
    const [count, setCount] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            currentImage === 2 ? setCurrentImage(0) : setCurrentImage(currentImage + 1);

        }, 750);
        return () => clearInterval(interval);
    }, [currentImage])

    return (
        <>
            <div className='container-fluid mainMintContainer  h-100'>
                <div className='row w-100 h-100'>
                    <div className='col-12'>
                        <div
                            className='mintContainer col-12 d-flex flex-lg-row flex-column justify-content-between align-items-center'>
                            <div className='mintLeft'>
                                <img src={nftArray[currentImage]} alt='' className='mintImage'/>
                            </div>
                            <div className='mintRight'>
                                <div className='mintHeading'>
                                    <h2 className='text-white'>Skinrunners Genesis Serie Mint</h2>
                                </div>
                                <div className='mintAmount'>
                                    <div className='left' onClick={() => {
                                        count === 0 ? setCount(0) : setCount(count - 1);
                                    }}>
                                        <p>-</p>
                                    </div>
                                    <input type='text' value={count}/>
                                    <div className='right' onClick={() => {
                                        setCount(count + 1);
                                    }}>
                                        <p>+</p>
                                    </div>
                                </div>
                                <div className='mintButton'>
                                    <button>Mint</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/></>
    );
}

export default Mint;
