import React from "react";
import './UpComingProjectCards.css';
import UpComingProjectCards from "../../components/upComingProjectCard/UpComingProjectCard";
import { useTranslation } from "react-i18next";


const Projects = () => {

    const {t} = useTranslation();

    return (
        <div>
            <div className="projectsContainer">
                <div className="projectsTextContainer">
                    <p className="upComingProjectsSubTitle2">{t('projects.upcomingProjects.title')}</p>
                </div>
                <div className="projectsCardsContainer">
                    <div className='projectsCardsUpcomingMargin'>
                        <UpComingProjectCards/>
                    </div>
                    <div>
                        <UpComingProjectCards/>
                    </div>

                </div>
            </div>
        </div>

    );
}
export default Projects;
