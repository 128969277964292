import React, { useState } from 'react'
import './RecordLabel.css'
import Title from '../../components/title/Title'
import bohemlogo from '../../assets/BOHEM.png'
import logo from '../../assets/rocknversebig.png'
import dominageLogo from '../../assets/dominage.png'

import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import pixxicastle from '../../assets/Pixxi_Castle_Logo.png'
import xtinge from '../../assets/xtinge-logo-named-orange-en.png'
import tomtom from '../../assets/Tom_Tom_Logo.png'

const studio1 = 'https://i.hizliresim.com/7x2wldi.jpg'
const studio2 = 'https://i.hizliresim.com/gro750s.jpg'
const studio3 = 'https://i.hizliresim.com/gchzrpu.jpg'
const studio4 = 'https://i.hizliresim.com/tnr7zzl.jpg'
//
const imageArray = [studio1, studio2, studio3, studio4]

const RecordLabel = () => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)
  const [isBigGalleryOpen, setIsBigGalleryOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)

  const nextImage = () => {
    if (currentImage !== 3) {
      setCurrentImage(currentImage + 1)
    } else {
      setCurrentImage(0)
    }
  }
  const backImage = () => {
    if (currentImage !== 0) {
      setCurrentImage(currentImage - 1)
    } else {
      setCurrentImage(3)
    }
  }

  return (
    <div className='recordLabelContainer'>
      <div className='recordLabelHeading'>
        <p className='recordLabelTitle'>
          {t('recordLabel.title.titleParagraph')}
        </p>
        <p>
          <Title title={t('recordLabel.title.titleComponent')} />
        </p>
        <div className='description'>
          <p>
            {t('recordLabel.descripiton.first')}
            <br />
            {t('recordLabel.descripiton.second')}
            <br />
            {t('recordLabel.descripiton.third')}
          </p>
        </div>
      </div>
      <div className='row recordLabelImgContainer justify-content-center col-12 align-items-center'>
        <div className='rocknverse col-12 col-lg-6 mt-4 mb-5 d-flex justify-content-center'>
          <img src={logo} loading='lazy' alt='' />
        </div>
        <div className='bohem col-12 col-lg-6 mb-5 d-flex justify-content-center'>
          <img src={bohemlogo} loading='lazy' alt='' />
        </div>
      </div>
      <div className='ButtonContainer d-flex justify-content-center'>
        <div className='gallerySection'>
          <button onClick={() => setIsOpen(!isOpen)}>
            {isOpen
              ? t('recordLabel.closeButton')
              : t('recordLabel.seeGallery')}
          </button>
          <p style={{ display: isOpen ? 'block' : 'none' }}>
            <div className='row mt-5'>
              <div
                className='bohem col-12 col-xl-6 d-flex justify-content-end mb-5'
                onClick={() => {
                  setIsBigGalleryOpen(true)
                  setCurrentImage(0)
                }}
              >
                <img loading='lazy' src={studio1} alt='' />
              </div>
              <div
                className='bohem col-12 col-xl-6 d-flex justify-content-start mb-5'
                onClick={() => {
                  setIsBigGalleryOpen(true)
                  setCurrentImage(1)
                }}
              >
                <img loading='lazy' src={studio2} alt='' />
              </div>
              <div
                className='bohem col-12 col-xl-6 d-flex justify-content-end'
                onClick={() => {
                  setIsBigGalleryOpen(true)
                  setCurrentImage(2)
                }}
              >
                <img loading='lazy' src={studio3} alt='' />
              </div>
              <div
                className='bohem col-12 col-xl-6 d-flex justify-content-start'
                onClick={() => {
                  setIsBigGalleryOpen(true)
                  setCurrentImage(3)
                }}
              >
                <img loading='lazy' src={studio4} alt='' />
              </div>
            </div>
          </p>
        </div>
      </div>
      <Modal show={isBigGalleryOpen} size='lg'>
        <Modal.Body>
          <div className='bigGalleryContainer'>
            <img
              className='galleryImage'
              loading='lazy'
              src={imageArray[currentImage]}
              alt=''
            />
            <div className='galleryButtonContainer'>
              <button className='galleryBack' onClick={backImage}>
                {'<'}
              </button>
              <button className='galleryNext' onClick={nextImage}>
                {'>'}
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            className='modalFooter'
            onClick={() => setIsBigGalleryOpen(false)}
          >
            {t('recordLabel.closeButton')}
          </div>
        </Modal.Footer>
      </Modal>
      <div className='sponsorsHeading'>
        <p>
          <Title title={t('sponsorsAndPartners.title')} />
        </p>
        <div className='row my-5 col-12 justify-content-center'>
          <div className='col-lg-3 col-3 mx-5'>
            <a
              href='https://noras.tech'
              target='_blank'
              style={{ display: 'inline-block' }}
            >
              <img
                src={'https://noras.tech/noras-light.png'}
                loading='lazy'
                alt=''
                width={300}
              />
            </a>
          </div>
          <div className='col-lg-3 col-3'>
            <a href='' target='_blank' style={{ display: 'inline-block' }}>
              {' '}
              <img src={'/skyweldrix.png'} loading='lazy' alt='' width={250} />
            </a>
          </div>
          <div className='col-lg-2 col-2'>
            <a href='' target='_blank' style={{ display: 'inline-block' }}>
              {' '}
              <img src={xtinge} loading='lazy' alt='' width={100} />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecordLabel
