import React from 'react';
import './ProjectCard.css';
import image from '../../assets/Untitled-3.jpg';
import discord from '../../assets/discord-line.png';
import instagram from '../../assets/instagram-line.png';
import twitter from '../../assets/twitter-line.png';
import medium from '../../assets/medium-line.png';
import borderImage from '../../assets/borderEffect.png';
import { useTranslation } from 'react-i18next';

const ProjectCard = ({ soon }: any) => {

    const { t } = useTranslation();


    return (
        <div className='container-fluid'>
            <div className='projectCardContainer'>
                <div className="projectCardRightBottom">
                    <p>{t('projects.projectCard.soon')}</p>
                </div>
                <div className='innerContainer'>
                    <img className='borderImageFirst' loading='lazy' src={borderImage} alt=''/>
                    <img className='borderImageSecond' loading='lazy' src={borderImage} alt=''/>
                    <div className='projectCardLeft'
                         onClick={() => window.open('https://skinrunners.rocknverse.com', '_blank')}>
                        <img src={image}
                             loading='lazy' alt=''/>
                    </div>
                    <div className='projectCardRight'>
                        <div className='projectCardRightTop'>
                            <p>{t('projects.projectCard.title.titleFirst')} </p>
                            <p>{t('projects.projectCard.title.titleSecond')}</p>
                            <div className="projectCardSocials">
                                <a target='_blank' rel="noreferrer" href='https://discord.com/invite/rocknverse'><img src={discord}
                                                                                                     loading='lazy'
                                                                                                     alt=""/></a>
                                <a target='_blank' rel="noreferrer"
                                   href='https://www.instagram.com/rocknverse'><img
                                    src={instagram} loading='lazy' alt=""/></a>
                                <a target='_blank' href='https://twitter.com/rocknverse'><img src={twitter}
                                                                                              loading='lazy'
                                                                                              alt=""/></a>
                                <a target='_blank' rel="noreferrer" href='https://medium.com/@rocknverse'><img src={medium}
                                                                                              loading='lazy'
                                                                                              alt=""/></a>
                            </div>
                            <p className='projectCardDescription'>{t('projects.projectCard.description')}</p>
                            <p className='projectCardDescription'>{t('projects.projectCard.description2')}</p>
                        </div>
                        <div className="projectCardRightCenter">
                            <div className="projectCardAttributeContainer">
                                <div className="row">
                                    <div className="projectCardAttribute col-12 col-lg-6">
                                        <p>{t('projects.projectCard.blockchain')}</p>
                                        <p>ETH</p>
                                    </div>
                                    <div className='projectCardAttribute col-12 col-lg-6'>
                                        <p>{t('projects.projectCard.totalRaise')}</p>
                                        <p>3.333</p>
                                    </div>
                                    <div className='projectCardAttribute col-12 col-lg-6'>
                                        <p>{t('projects.projectCard.preSale')}</p>
                                        <p>TBA</p>
                                    </div>

                                    <div className='projectCardAttribute col-12 col-lg-6'>
                                        <p>{t('projects.projectCard.preSalePrice')}</p>
                                        <p>{t('projects.projectCard.tba')}</p>
                                    </div>
                                    <div className='projectCardAttribute col-12 col-lg-6'>
                                        <p>{t('projects.projectCard.publicSale')}</p>
                                        <p>TBA</p>
                                    </div>
                                    <div className='projectCardAttribute col-12 col-lg-6'>
                                        <p>{t('projects.projectCard.publicSalePrice')}</p>
                                        <p>{t('projects.projectCard.tba')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='projectCardRightBottomContainer'>
                            <a href='https://skinrunners.rocknverse.com/' target='_blank'>
                                <p>{t('projects.projectCard.goto')}</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default ProjectCard;
