import React, { useEffect } from "react";
import WhitePaperBox from "../whitePaperBox/WhitePaperBox";
import { useTranslation } from "react-i18next";

const WhitepaperInnerItem = ({ headingFirst, headingDescription, headingSecond, description }: any) => {

    const {t} = useTranslation();

    return (
        <div className="whitePaperContentContainer">
            <div className="whitePaperHeading">
                <h2>{headingFirst}</h2>
                <p> {headingDescription}</p>
            </div>
            {headingFirst === 'Litepaper' || headingFirst  === 'Aksiyon Sunumu' ?
                <div className='d-flex flex-column flex-lg-row justify-content-around'>
                    <WhitePaperBox heading={'English'} downloadLink={undefined}
                                  />
                    <WhitePaperBox heading={'Türkçe'} downloadLink={undefined}
                                   />
                </div>
                :
                <div className="whitePaperContent">
                    <h4>{headingSecond}</h4>
                    {description.map((currentDescription: { descriptionText: string, descriptionImg: string, isFootage: boolean }) => {
                            return (
                                <div>
                                    <p>{currentDescription.descriptionText}</p>
                                    {currentDescription.descriptionImg ?
                                        <img loading='lazy' src={currentDescription.descriptionImg} alt=""/> :
                                        <span/>}
                                    {currentDescription.isFootage ? <p> {t('readmore.about.example')} </p> : <span/>}
                                </div>
                            );
                        }
                    )}
                </div>}
        </div>
    );

}
export default WhitepaperInnerItem;
