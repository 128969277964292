import React from 'react'
import './TeamMembers.css'
import TeamMember from '../../components/teamMember/TeamMember'
import Title from '../../components/title/Title'
import selcukeser from '../../assets/Selçuk Eser_Founder.jpg.png'
import egemenkum from '../../assets/Egemen Kum_Artist.jpeg'
import tulgarcinar from '../../assets/Tulgar Çınar_Advisor.jpeg'
import pinarcaki from '../../assets/Pınar Çakı_music management.jpeg'
import suatarmagankocak from '../../assets/Suat Armağan Koçak_Labeling Manager.jpeg'
import twitter from '../../assets/twitter-line.png'
import linkedin from '../../assets/linkedin.png'
import instagram from '../../assets/instagram-line.png'
// import gozdevanli from '../../assets/Gözde Vanlı_Co-founder.png';
import djozzy from '../../assets/DJ Ozzy_portrait. TEAM= BROADCAST DJ.jpg'
import taylan from '../../assets/Taylan Sulaoğlu_portrait.jpg'
import emel from '../../assets/8. Emel KÜPÇÜ_METAVERSE DEPARTMENT CHIEF.jpg'
import hakan from '../../assets/7. Hakan Dinç_3D ARTIST.jpg'
import prof from '../../assets/Prof. Burak BAL_International Network.jpg'
import hakandogan from '../../assets/titleBackground.png'
import silankusi from '../../assets/titleBackground.png'
import simabaktas from '../../assets/titleBackground.png'
import hilalbaktas from '../../assets/titleBackground.png'
import ibrahimUzun from '../../assets/titleBackground.png'
import jokerBg from '../../assets/titleBackground.png'

import { useTranslation } from 'react-i18next'

const TeamMembers = () => {
  const { t } = useTranslation()

  return (
    <div className='teamMembersContainer' id='teamMembers'>
      <div className='teamMembersHeading'>
        <p className='teamMemberTitle'>
          {t('teamMembers.title.titleParagraph')}
        </p>
        <p>
          <Title title={t('teamMembers.title.titleComponent')} />
        </p>
      </div>
      <div className='bandMembersCards'>
        <div className='container-fluid'>
          <div className='row justify-content-center'>
            <div className='col-6 col-md-6 col-lg-4 col-xl-2'>
              <TeamMember
                teamMemberImg={selcukeser}
                teamMemberName={'Selçuk Eser'}
                teamMemberTitle={t('teamMembers.selcuk')}
              />
              <div className='SocialMediaIcons'>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://www.instagram.com/selbond007/'
                >
                  <img src={instagram} loading='lazy' alt='' />
                </a>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://twitter.com/selbond007'
                >
                  <img src={twitter} loading='lazy' alt='' />
                </a>
              </div>
            </div>
            <div className='col-6 col-md-6 col-lg-4 col-xl-2'>
              <TeamMember
                teamMemberImg={jokerBg}
                teamMemberName={'Kerem Noras'}
                teamMemberTitle={t('teamMembers.keremnoras')}
              />
              <div className='SocialMediaIcons'>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://twitter.com/KeremNoras/'
                >
                  <img src={twitter} loading='lazy' alt='' />
                </a>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://www.linkedin.com/in/keremnoras/'
                >
                  <img src={linkedin} loading='lazy' alt='' />
                </a>
              </div>
            </div>
            <div className='col-6 col-md-6 col-lg-4 col-xl-2'>
              <TeamMember
                teamMemberImg={jokerBg}
                teamMemberName={'Yiğit Bulut'}
                teamMemberTitle={t('teamMembers.yigitbulut')}
              />
              <div className='SocialMediaIcons'>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://twitter.com/rixycraft/'
                >
                  <img src={twitter} loading='lazy' alt='' />
                </a>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://www.linkedin.com/in/yi%C4%9Fit-bulut-2882a0220/'
                >
                  <img src={linkedin} loading='lazy' alt='' />
                </a>
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-6 col-md-6 col-lg-4 col-xl-2'>
              <TeamMember
                teamMemberImg={jokerBg}
                teamMemberName={'Buket Buğdaycıoğlu'}
                teamMemberTitle={t('teamMembers.buket')}
              />
              {/* <div className='SocialMediaIcons'>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://twitter.com/KeremNoras/'
                >
                  <img src={twitter} loading='lazy' alt='' />
                </a>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://www.linkedin.com/in/keremnoras/'
                >
                  <img src={linkedin} loading='lazy' alt='' />
                </a>
              </div> */}
            </div>
            <div className='col-6 col-md-6 col-lg-4 col-xl-2'>
              <TeamMember
                teamMemberImg={egemenkum}
                teamMemberName={'Egemen Kum'}
                teamMemberTitle={t('teamMembers.egemen')}
              />
            </div>

            <div className='col-6 col-md-6 col-lg-4 col-xl-2'>
              <TeamMember
                teamMemberImg={hakan}
                teamMemberName={'Hakan Dinç'}
                teamMemberTitle={t('teamMembers.hakan')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeamMembers
