import React from "react";
import './Projects.css';
import Title from "../../components/title/Title";
import ProjectCard from "../../components/projectCard/ProjectCard";
import { useTranslation } from "react-i18next";
//giwd

const Projects = () => {
    const {t} = useTranslation();

    return (
        <div>
            <div className="projectsContainer">
                <div className="projectsTextContainer">
                    <p className="projectsSubTitle" id="Launchpad">{t('projects.titleParagraphOne')}</p>
                    <p>
                        <Title title={t('projects.titleComponentOne')}/>
                    </p>
                    <p className="projectsSubTitle" >{t('projects.titleParagraphTwo')}</p>
                    <p className="projectsSubTitle2">{t('projects.titleParagraphThree')}</p>
                </div>
                <div className="projectsCardsContainer">
                    <ProjectCard soon={false}/>
                </div>
            </div>
        </div>
    );
}
export default Projects;
