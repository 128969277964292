import React from "react";
import "./Description.css"
import Title from "../../components/title/Title"
import skull from "../../assets/Adsz_tasarm_2.png"
import { Parallax } from "react-scroll-parallax";
import { useTranslation } from "react-i18next";


const Description = () => {

    const { t } = useTranslation();

    return (
        <div className='descriptionContainer'>
            <div className="upperTitles">
                <p>{t('description.titleParagraphOne')} </p>
                <p><Title title={t('description.titleComponent')}/></p>
                <p>{t('description.titleParagraphTwo')}</p>
            </div>
            <div className="img">

                    <img loading='lazy' className="descriptionImg" src={skull} alt=""/>

            </div>
            <div className="description">
                <p>{t('description.description')}</p>
            </div>
            <div className="lowerTitle">
                <p>{t('description.lowerTitleFirst')}
                    <span>{t('description.lowerTitleSpan')}</span></p>
                   <p> {t('description.lowerTitleSecond')}</p>
            </div>

        </div>
    );
}

export default Description
