import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './containers/home/Home';
import Whitepaper from './containers/whitePaper/Whitepaper';
import Navbar from './containers/navbar/Navbar';
import ScrollToTop from 'react-scroll-to-top';
import NotFoundPage from './containers/notFoundPage/notFoundPage';
import ReactGA from 'react-ga'
import Mint from './containers/mint/Mint';


class App extends Component<any, any> {
    initReactGA = () => {
        ReactGA.initialize('G-D9SYYV5S60');
        ReactGA.pageview('test-init-pageview');
    };

    componentDidMount() {
        this.initReactGA();
    }

    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <Router>
                <div className='container'>
                    <Navbar/>
                </div>

                <Routes>
                    <Route path='/' element={<Home/>}/>
                    <Route path='/readMore' element={<Whitepaper/>}/>
                    <Route path='/static' element={<Home/>}/>
                    <Route path='/mint' element={<Mint/>}/>
                    <Route path={'*'} element={<NotFoundPage/>}/>
                </Routes>
                <ScrollToTop smooth color='white' style={{ backgroundColor: 'red' }}/>
            </Router>

        );
    }
}

export default App;
