import React from "react";
import './Footer.css';
import logo from '../../assets/rocknversebig.png';
import bohemlogo from '../../assets/BOHEM.png';
import ctlogo from "../../assets/cointurk.png"
import discord from "../../assets/discord-line.png";
import instagram from "../../assets/instagram-line.png";
import twitter from "../../assets/twitter-line.png";
import medium from "../../assets/medium-line.png";
import youtube from "../../assets/yticon.png"
import { useTranslation } from "react-i18next";

const Footer = () => {

    const { t } = useTranslation();

    return (

        <div className='footerContainer'>
            <div className="row footerSponsorContainer justify-content-center mb-5 col-12 align-items-center">
                <div className="bohem col-12 mt-4 mb-4 col-lg-4">
                    <img src={bohemlogo} loading='lazy' alt=""/>
                </div>
                <div className="col-12 mt-2 mb-4 col-lg-4">
                    <img className='footerLogo' src={logo} loading='lazy' alt=""/>
                </div>
            </div>

            <div className="footerCopyright">
                <p>{t('footer.copyright')}</p>
            </div>
            <div className="footerMenu">

                <a href="#teamMembers" rel="noopener" className="navbarLink mb-2">{t('footer.team')}</a>
                <a href="#Roadmap" rel="noopener" className="navbarLink mb-2">{t('footer.roadmap')}</a>
                <a href="#Launchpad" rel="noopener" className="navbarLink  mb-2">{t('footer.launchpad')}</a>
                <a href="https://skinrunners.rocknverse.com" target="_blank" rel="noopener" className="navbarLink mb-2">{t('footer.skinrunners')} <div>{t('footer.soon')}</div></a>
            </div>
            <div className="footerSocials">
                <a target='_blank' rel="noreferrer" href='https://www.youtube.com/c/ROCKNVERSE'><img src={youtube}
                                                                                                     loading='lazy' alt=""/></a>
                <a target='_blank' rel="noopener" href='https://discord.com/invite/rocknverseofficial'><img src={discord}
                                                                                             loading='lazy' alt=""/></a>
                <a target='_blank' rel="noopener" href='https://www.instagram.com/rocknverse/'><img
                    src={instagram} loading='lazy' alt=""/></a>
                <a target='_blank' rel="noopener"   href='https://twitter.com/rocknverse'><img src={twitter} loading='lazy' alt=""/></a>
                <a target='_blank' rel="noopener" href='https://medium.com/@rocknverse'><img src={medium} loading='lazy' alt=""/></a>
            </div>
            <div className="mail">
                <a href="mailto:info@rocknverse.com">info@rocknverse.com</a>
            </div>
        </div>
    );
};

export default Footer;
