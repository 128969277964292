import React from "react";
import classNames from 'classnames'
import "./GuitarLine.css"

export interface GuitarLineProps {
    type: GuitarLineType
    direction: GuitarLineDirection
    title: string
    description: string
    subDescription1?: string
    subDescription2?: string
    subDescription3?: string
    subDescription4?: string
    width: number
    className?: string
}

export enum GuitarLineType {
    FIRST = "First",
    SECOND = "Second",
    THIRD = "Third",
    FOURTH = "Fourth",
}

export enum GuitarLineDirection {
    LEFT = "Left",
    RIGHT = "Right",
}

const GuitarLine = ({direction,subDescription1,subDescription2,subDescription3,subDescription4,description,type,title,width, className}:GuitarLineProps) => {
    return (
        <div className={classNames("wrapper", "type" + type,"direction" + direction, className)}>
            <div className={classNames("lineAndDot", "type" + type,"direction" + direction, className)}>
                <div className="line"/>
                <div className="dot" />
            </div>
            <div className={classNames("textArea", "direction" + direction)}>
                <span className="guitarLineTitle">{title}</span>
                <span className="guitarLineDescription">{description}</span>
                {subDescription1 && <span className="guitarLineSubDescription">{subDescription1}</span>}
                {subDescription2 && <span className="guitarLineSubDescription">{subDescription2}</span>}
                {subDescription3 && <span className="guitarLineSubDescription">{subDescription3}</span>}
                {subDescription4 && <span className="guitarLineSubDescription">{subDescription4}</span>}
            </div>
        </div>
    );
}

export default GuitarLine;
