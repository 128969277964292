import { createSlice } from "@reduxjs/toolkit";


export const walletSlice = createSlice({
    name: 'wallet',
    initialState: {
        currentWallet: '',
        isOpenModal: false,
    },
    reducers: {
        setWallet: (state, action) => void (state.currentWallet = action.payload),
        setIsOpenModal: (state) => void (state.isOpenModal = !state.isOpenModal)
    }

});

export const { setWallet, setIsOpenModal } = walletSlice.actions;

export default walletSlice.reducer;
