import React from "react";
import './RockCommunityCard.css';

// @ts-ignore
const RockCommunityCard = ({ cardImage, cardParagraph, cardHeading }) => {
    return (
        <div className='rockCommunityCardContainer'>
            <div className="rockCommunityCardImg">
                <img src={cardImage} loading='lazy' alt=""/>
            </div>
            <div className="rockCommunityCardHeading">
                <p>{cardHeading}</p>
            </div>
            <div className="rockCommunityCardParagraph">
                <p>{cardParagraph}</p>
            </div>
        </div>
    );
}

export default RockCommunityCard;
