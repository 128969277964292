import React from "react";
import './FurtherProjects.css';
import Title from "../../components/title/Title";
import RockCommunityCard from "../../components/rockCommunityCard/RockCommunityCard";
import img1 from "../../assets/cardImg4.png";
import img2 from "../../assets/cardImg5.png";
import img3 from "../../assets/cardImg6.png";
import { useTranslation } from "react-i18next";


const FurtherProjects = () => {

    const { t } = useTranslation();

    const FurtherProjectsItems = [{
        heading: t('furtherProjects.cardOne.heading'),
        img: img1,
        paragraph: t('furtherProjects.cardOne.paragraph')
    },
        {
            heading: t('furtherProjects.cardTwo.heading'),
            img: img2,
            paragraph: t('furtherProjects.cardTwo.paragraph')
        },
        {
            heading: t('furtherProjects.cardThree.heading'),
            img: img3,
            paragraph: t('furtherProjects.cardThree.paragraph')
        }];

    return (
        <div className='furtherProjectContainer'>
            <div className="rockCommunityTitle">
                <div className="rockCommunityTitleText">
                    <p className='rockCommunityTitleFirst'>{t('furtherProjects.title')}</p>
                    <Title title={t('furtherProjects.titleComponent')}/>
                </div>
            </div>
            <div className="container-fluid">
                <div className="rockCommunityCards">

                    <div className="row">
                        {
                            FurtherProjectsItems.map((curr) => {
                                return (
                                    <div className='col-12 col-lg-4'>
                                        <RockCommunityCard cardImage={curr.img} cardHeading={curr.heading}
                                                           cardParagraph={curr.paragraph}/>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FurtherProjects;
